import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from '../../../components/layout'
import Hero from '../../../components/cloud-studio/hero'
import Card from '../../../components/cloud-studio/card'
import Button from '../../../components/cloud-studio/button'
import CardFullWidthImage from '../../../components/cloud-studio/card-full-width-image'
import SectionTitle from '../../../components/cloud-studio/section-title'

// Assets
import Guidance from '../../../images/cloud-studio/guidance.inline.svg'

function WHMCSPage() {
  const images = useStaticQuery(graphql`
    {
      easyDeploy: file(relativePath: { eq: "cloud-studio/easy-deploy.png" }) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      cloudMetalCard: file(
        relativePath: { eq: "cloud-studio/cloud-metal-card-bg.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      cloudVpsCard: file(
        relativePath: { eq: "cloud-studio/cloud-vps-card-bg.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      infrastructureCard: file(
        relativePath: { eq: "cloud-studio/infrastructure-card-bg.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 400
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      totalControl: file(
        relativePath: { eq: "cloud-studio/total-control.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      operations: file(relativePath: { eq: "cloud-studio/operations.png" }) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
    }
  `)

  return (
    <Layout
      mainClass={'bg-[#0D0E11] font-roobert'}
      headerVariant={'dark'}
      footerVariant={'dark'}
    >
      <Hero
        label="WHMCS"
        title="Unlock business automation with the "
        rotatingTitles={['Liquid Web WHMCS plugin']}
        subtitle="Resell our complete line of cloud solutions with confidence using our free WHMCS plugin. Automate client management, support, billing, and more from the plugin’s simple interface."
        primaryCta={{
          text: 'Get the plugin',
          url: 'https://github.com/liquidweb/LiquidWeb-WHMCS-Plugin',
          target: '_blank',
          variant: 'primary',
          as: 'button',
        }}
      />

      <div className="container mb-16">
        <SectionTitle title="Scale up through our high-quality cloud solutions with ease" />
        <div className="grid gap-y-10 lg:gap-x-10 lg:grid-cols-12">
          <CardFullWidthImage
            className="col-span-12 lg:col-span-7"
            variant="dark"
            title="Easy deploys"
            description="Create, cancel, and preconfigure Cloud VPS and Cloud Metal deployments automatically."
            image={images?.easyDeploy}
          />
          <Card
            className="col-span-12 lg:col-span-5"
            variant="dark"
            title="Tailored instance sizes"
            description="Add value for your customers by configuring customizable instance sizes."
          />
          <Card
            className="col-span-12 lg:col-span-5"
            variant="dark"
            title="Top-quality servers"
            description="Our VPS solutions outperform Amazon and DigitalOcean in processing power and Disk IOPS."
          />
          <Card
            className="col-span-12 lg:col-span-7"
            variant="dark"
            icon={<Guidance />}
            title="Helpful guidance"
            description={
              <>
                Learn to set up, install, and use the plugin with our detailed{' '}
                <Link to="/kb/" className="underline text-lw-text-red">
                  Knowledge Base
                </Link>{' '}
                articles.
              </>
            }
          />
        </div>
      </div>

      <div className="container mb-32 flex justify-center">
        <Button
          as="button"
          text="Get the plugin"
          variant="primary"
          url="https://github.com/liquidweb/LiquidWeb-WHMCS-Plugin"
          target="_blank"
        />
      </div>
    </Layout>
  )
}

export default WHMCSPage
